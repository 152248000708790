import { Box, Flex, Grid, GridProps, Image } from "@chakra-ui/core"
import React from "react"
import Body from "../typography/Body"
import Heading from "../typography/Heading"
import { bp } from "../../utils/MediaQueries"
import Notice from "../Notice"
import FormattedTitle from "../FormattedTitle"

export type OrderItemProps = GridProps & {
  formattedTitle: string
  image: string
  imageMaxHeight?: string
  price: string
  details?: React.ReactNode
  customizations?: {
    image: string
    name: string
  }[]
  quantity: number
  preorderLabel?: string
  refund?: string | null
}

const OrderItem: React.FC<OrderItemProps> = ({
  formattedTitle,
  image,
  imageMaxHeight,
  price,
  details,
  quantity,
  customizations,
  preorderLabel,
  refund,
  ...props
}) => {
  return (
    <Grid
      pt={bp("1.6638rem", "3.3125rem")}
      pb={bp("1.25rem", "3.375rem")}
      pr={bp("1.25rem", "2.3944rem")}
      pl={bp("1.25rem", "1.375rem")}
      gridTemplateAreas={[
        `
          "image title"
          "image title"
          "custom custom"
          "price price"
        `,
        null,
        null,
        null,
        `
          "image title price"
          "image title price"
          "image custom price"
        `,
      ]}
      columnGap="0.75rem"
      rowGap={bp("1rem", "0")}
      borderRadius="0.5rem"
      border="1px solid"
      borderColor="dividerLine"
      {...props}
    >
      <Flex
        gridArea="image"
        align="center"
        justify="center"
        direction="column"
        flex={2}
        width="9.625rem"
        // maxWidth="21.875rem"
      >
        <Image
          width="100%"
          maxHeight={imageMaxHeight}
          objectFit={imageMaxHeight ? "contain" : undefined}
          px="1rem"
          src={image}
        />
      </Flex>
      <Box gridArea="title">
        <Heading as="span" display="block" size="6" mb="1.5625rem">
          <FormattedTitle raw={formattedTitle} />
        </Heading>
        {details && (
          <Body size="md" color="dawn" mb="1.0625rem">
            {details}
          </Body>
        )}
        {preorderLabel && <Notice>{preorderLabel}</Notice>}
      </Box>
      <Box gridArea="custom" maxW={[null, null, null, null, "18.75rem"]}>
        {customizations &&
          customizations.map((item, index) => (
            <Flex
              key={index}
              border="1px solid"
              borderColor="dividerLine"
              borderRadius="0.5rem"
              py="0.25rem"
              pr="1rem"
              pl="0.75rem"
              mb="0.5325rem"
              align="center"
            >
              <Flex
                size="2.8125rem"
                align="center"
                justify="center"
                mr="0.6012rem"
              >
                <Image src={item.image} width="100%" />
              </Flex>
              <Body size="md" color="dawn">
                {item.name}
              </Body>
            </Flex>
          ))}
      </Box>
      <Flex
        gridArea="price"
        align={bp("center", "flex-end")}
        justify={bp("space-between", "flex-start")}
        direction={bp("row", "column")}
        flex={1}
      >
        {refund && (
          <Body
            size="md"
            fontWeight="semibold"
            color="primary"
            mb={bp(null, "1rem")}
            order={bp(2, 1)}
          >
            Refunded: {refund}
          </Body>
        )}
        <Body
          size="md"
          fontWeight="semibold"
          color="primary"
          mb={bp(null, "2rem")}
          order={bp(2, 1)}
        >
          {price}
        </Body>
        <Body size="md" color="dusk" order={bp(1, 2)}>
          Qty: {quantity}
        </Body>
      </Flex>
    </Grid>
  )
}

export default OrderItem
