import React from "react"
import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  SimpleGrid,
} from "@chakra-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "gatsby"

import PageContainer from "../../components/PageContainer"
import UserOnlyAccessWrapper from "../../components/Account/UserOnlyAccessWrapper"
import Header from "../../components/Account/Header"
import MaxWidthGrid from "../../components/Layout/MaxWidthGrid"
import Body from "../../components/typography/Body"
import OrderItem from "../../components/Orders/OrderItem"
import DetailsItem from "../../components/DetailsItem"
import ExternalLink from "../../components/ExternalLink"
import { Button } from "../../components/Buttons"

import {
  getFixedPrice,
  groupCustomizedLineItems,
} from "../../components/Cart/utils"
import { getNormalizedCustomAttributes } from "../../redux/models/checkout"
import { BaseDispatch, BaseRootState } from "redux/store"
import format from "date-fns/format"
import { bp } from "../../utils/MediaQueries"
import { placeholderImage } from "../../utils"

const OrdersPage = ({ path }) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { bikes, accessories, orderHistory } = useSelector(
    (state: BaseRootState) => ({
      bikes: state.bikes.bikes,
      accessories: state.bikes.accessories,
      orderHistory: state.user.orderHistory,
    })
  )

  React.useEffect(() => {
    dispatch.sidebar.setIsOpen(false)
    dispatch.user.refreshUserData()
  }, [])

  return (
    <PageContainer>
      <MaxWidthGrid>
        <UserOnlyAccessWrapper>
          <Header
            active="orders"
            gridColumn={["1 / 3", null, null, null, "2 / 14", "4 / 12"]}
          >
            {orderHistory.length === 0 && (
              <Body color="dusk">No orders available</Body>
            )}
            <Accordion allowMultiple>
              {orderHistory.map((order) => {
                const lineItems =
                  order.items?.map((item, index) => ({
                    node: { id: `${index}`, ...item },
                  })) || []
                const groupedLineItems = groupCustomizedLineItems<
                  typeof lineItems[0]
                >(lineItems)

                const totalDiscount =
                  order?.items?.reduce((acc, next) => {
                    return acc + (Number(next.discount.amount) || 0)
                  }, 0) || 0
                const totalRefund =
                  order?.totalRefunded?.amount &&
                  order?.totalRefunded?.amount !== "0.0"
                    ? getFixedPrice(order.totalRefunded.amount)
                    : null
                return (
                  <AccordionItem key={order.orderNumber} border="0">
                    {({ isExpanded }) => (
                      <Box
                        backgroundColor={isExpanded ? "white" : "transparent"}
                        borderRadius="0.5rem"
                        transition="background-color ease 100ms"
                        boxShadow={isExpanded ? "big" : "none"}
                      >
                        <AccordionHeader
                          px="1rem"
                          py="1rem"
                          display="flex"
                          justifyContent="space-between"
                          borderBottom={isExpanded ? "0px" : "1px solid"}
                          borderColor="dividerLine"
                          _active={{
                            boxShadow: "none",
                          }}
                          _focus={{
                            boxShadow: "none",
                          }}
                        >
                          {order.timestamp && (
                            <Body size="md" fontWeight="bold">
                              {format(new Date(order.timestamp), "PP")}
                            </Body>
                          )}
                          <Flex>
                            <Body size="md" fontWeight="bold" mr="1rem">
                              {getFixedPrice(order.total)}
                            </Body>
                            <AccordionIcon />
                          </Flex>
                        </AccordionHeader>
                        <AccordionPanel
                          backgroundColor="transparent"
                          boxShadow="none"
                          px="2.8125rem"
                        >
                          <Box mb="20">
                            <Body fontWeight="bold">
                              Order #: {order.orderNumber}
                            </Body>
                            {order.cancelReason && (
                              <Box>
                                <Body
                                  as="span"
                                  fontWeight="bold"
                                  color="sunrise"
                                  mr="0.5rem"
                                >
                                  Cancelled
                                </Body>
                                <Body as="span" size="sm">
                                  {" "}
                                  {order.cancelReason}
                                </Body>
                              </Box>
                            )}
                          </Box>
                          <Flex wrap="wrap">
                            {/* Order Line Items */}
                            <Box w={bp("100%", "60%")}>
                              {groupedLineItems.map((item) => {
                                if (item.customAttributes.length > 0) {
                                  const attributes = getNormalizedCustomAttributes(
                                    item.customAttributes
                                  )
                                  const type = attributes["type"]
                                  const slug = attributes["productSlug"]
                                  const contentfulProductId =
                                    attributes["contentfulProductId"]
                                  const contentfulVariantId =
                                    attributes["contentfulVariantId"]
                                  const isPreorder = attributes["isPreorder"]
                                  const preorderInfo =
                                    attributes["preorderInfo"]

                                  let formattedTitle
                                  let details
                                  switch (type) {
                                    case "bike":
                                      const bicycle = bikes.find(
                                        (bike) => bike.internalTitle === slug
                                      )
                                      const product = bicycle?.speeds?.find(
                                        (speed) =>
                                          speed?.contentful_id ===
                                          contentfulProductId
                                      )
                                      const variant = product?.variants?.find(
                                        (variant) =>
                                          variant?.contentful_id ===
                                          contentfulVariantId
                                      )
                                      formattedTitle =
                                        bicycle?.formattedTitle! ||
                                        item.productTitle
                                      details = (
                                        <>
                                          <DetailsItem>
                                            {variant?.color?.name}
                                          </DetailsItem>
                                          <DetailsItem>
                                            {item.warranty
                                              ? "Forever Warranty"
                                              : "1 Year Warranty"}
                                          </DetailsItem>
                                        </>
                                      )
                                      break
                                    case "accessory":
                                      const accessory = accessories.find(
                                        ({ node }) =>
                                          node.internalTitle === slug
                                      )?.node
                                      const accVariant = accessory?.variants?.find(
                                        (variant) =>
                                          variant?.contentful_id ===
                                          contentfulVariantId
                                      )

                                      formattedTitle =
                                        accessory?.title || "(Name unavailable)"
                                      details = (
                                        <>
                                          <DetailsItem>
                                            {accVariant?.color?.name}
                                          </DetailsItem>
                                        </>
                                      )
                                      break
                                    case "warranty":
                                      formattedTitle = "Forever Warranty"
                                      break
                                  }

                                  return (
                                    <OrderItem
                                      key={item.id}
                                      w="100%"
                                      mb="1.25rem"
                                      formattedTitle={formattedTitle}
                                      details={details}
                                      customizations={item.accessories.map(
                                        (acc) => ({
                                          name: acc.productTitle,
                                          image:
                                            acc.variant?.image
                                              ?.transformedSrc ||
                                            placeholderImage.src,
                                        })
                                      )}
                                      image={
                                        item.variant?.image?.transformedSrc ||
                                        placeholderImage.src
                                      }
                                      imageMaxHeight={
                                        type !== "bike" ? "12.5rem" : undefined
                                      }
                                      price={getFixedPrice(item.price?.amount)}
                                      quantity={item.quantity}
                                      preorderLabel={
                                        isPreorder ? preorderInfo : undefined
                                      }
                                    />
                                  )
                                } else {
                                  // Purchased on old site
                                  const variantId = item.variant?.id
                                  let productTitle = item.productTitle
                                  let product
                                  let variant
                                  let details

                                  // Check if its a bike model
                                  const bike = bikes.find((bike) => {
                                    product = bike.speeds?.find((speed) => {
                                      variant = speed?.variants?.find(
                                        (speedVariant) =>
                                          speedVariant?.shopifyId === variantId
                                      )
                                      return variant
                                    })
                                    return product
                                  })
                                  if (bike) {
                                    productTitle = bike.formattedTitle || ""
                                    details = (
                                      <>
                                        <DetailsItem>
                                          {variant?.color?.name}
                                        </DetailsItem>
                                        <DetailsItem>
                                          {product?.speed} Speed
                                        </DetailsItem>
                                      </>
                                    )
                                  } else {
                                    // Check if its an accessory
                                    const accessory = accessories.find(
                                      (accessory) => {
                                        variant = accessory.node.variants?.find(
                                          (accVariant) =>
                                            accVariant?.shopifyId === variantId
                                        )
                                        return variant
                                      }
                                    )

                                    if (accessory) {
                                      productTitle = accessory.node.title!
                                      details = (
                                        <DetailsItem>
                                          {variant?.color?.name}
                                        </DetailsItem>
                                      )
                                    }
                                  }

                                  return (
                                    <OrderItem
                                      key={item.id}
                                      w="100%"
                                      mb="1.25rem"
                                      formattedTitle={productTitle}
                                      details={details}
                                      image={
                                        item.variant?.image?.transformedSrc ||
                                        placeholderImage.src
                                      }
                                      imageMaxHeight="12.5rem"
                                      price={getFixedPrice(item.price?.amount)}
                                      quantity={item.quantity}
                                    />
                                  )
                                }
                              })}
                            </Box>
                            {/* Order Summary */}
                            <SimpleGrid
                              columns={1}
                              spacingY="0.5625rem"
                              flex="1"
                              pl="3.75rem"
                              pr="2.295rem"
                              gridAutoRows="max-content"
                            >
                              {totalDiscount > 0 && (
                                <Flex justify="space-between" color="dawn">
                                  <Body>Discounts</Body>
                                  <Body color="primary">
                                    -{getFixedPrice(totalDiscount.toString())}
                                  </Body>
                                </Flex>
                              )}
                              {totalRefund && (
                                <Flex justify="space-between" color="dawn">
                                  <Body>Refunded</Body>
                                  <Body color="primary">+{totalRefund}</Body>
                                </Flex>
                              )}
                              <Flex justify="space-between" color="dawn">
                                <Body>Subtotal</Body>
                                <Body>{getFixedPrice(order.subtotal)}</Body>
                              </Flex>
                              <Flex justify="space-between" color="dawn">
                                <Body>Estimated Tax</Body>
                                <Body>{getFixedPrice(order.tax)}</Body>
                              </Flex>
                              <Flex justify="space-between" color="dawn">
                                <Body>Shipping</Body>
                                <Body>
                                  {Number(order.shipping) <= 0
                                    ? "Free"
                                    : getFixedPrice(order.shipping)}
                                </Body>
                              </Flex>
                              <Flex
                                justify="space-between"
                                color="night"
                                fontWeight="bold"
                              >
                                <Body>Total</Body>
                                <Body>{getFixedPrice(order.tax)}</Body>
                              </Flex>
                              {(order.fulfillments?.length ?? 0) > 0 && (
                                <Box mt="20">
                                  {order.fulfillments?.map(
                                    (fulfillment, index) => (
                                      <React.Fragment key={index}>
                                        {fulfillment.trackingInfo.map(
                                          (tracking) => (
                                            <Body key={tracking.number}>
                                              {fulfillment.trackingCompany} #:{" "}
                                              {tracking.url ? (
                                                <ExternalLink
                                                  to={tracking.url}
                                                  color="primary"
                                                >
                                                  {tracking.number}
                                                </ExternalLink>
                                              ) : (
                                                tracking.number
                                              )}
                                            </Body>
                                          )
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                                </Box>
                              )}
                              <Box mt="3.375rem">
                                {
                                  <Link to="/pages/contact-us?form=order-cancellation">
                                    <Button w="100%" theme="tertiary">
                                      Cancel Order
                                    </Button>
                                  </Link>
                                }
                              </Box>
                            </SimpleGrid>
                          </Flex>
                        </AccordionPanel>
                      </Box>
                    )}
                  </AccordionItem>
                )
              })}
            </Accordion>
          </Header>
        </UserOnlyAccessWrapper>
      </MaxWidthGrid>
    </PageContainer>
  )
}
export default OrdersPage
